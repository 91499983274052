import isEmpty from 'lodash/isEmpty';
import {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {v4 as uuidv4} from 'uuid';
import add from 'date-fns/add';
import addHours from 'date-fns/addHours';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import parse from 'date-fns/parse';
import {format} from 'date-fns-tz';
import {useI18nContext} from '@teladoc/pulse/ui/Context/i18n';
import Button from '@teladoc/pulse/ui/Button';
import DatePicker from '@teladoc/pulse/ui/DatePicker';
import Form from '@teladoc/pulse/ui/Form';
// import FormElementError from '@teladoc/pulse/ui/FormElementError';
import HorizontalRule from '@teladoc/pulse/ui/HorizontalRule';
import Image from '@teladoc/pulse/ui/Image';
import Label from '@teladoc/pulse/ui/Label';
import Radio from '@teladoc/pulse/ui/Radio';
import RadioGroup from '@teladoc/pulse/ui/RadioGroup';
import Select from '@teladoc/pulse/ui/Select';
// import Chip from '@teladoc/pulse/ui/Chip';
// import CheckableGroup from '@teladoc/pulse/ui/CheckableGroup';
import TextArea from '@teladoc/pulse/ui/TextArea';
// import {cs} from 'date-fns/locale';
import {useFeatureFlagContext} from '@livongo/utilities/system/featureFlag';
import Arg from '@livongo/arg';
import useTransLoader from 'i18n/use-trans-loader';
import MixpanelUtils from '../common/mix-panel';
import AcuityError from '../ErrorMessages/AcuityError';
import CommonUtils from '../utils/common-utils';
import UserInfo from '../UserInfo/UserInfo';
import SharedCoaching from '../utils/SharedCoaching';
import UserInfoAPI from '../UserInfo/userInfo-api';
import TimeTap from '../utils/TimeTap';
import {
    timeZones,
    defaultTimeZone,
    localizedTime,
    getClientTimeZoneObject,
} from '../utils/Timezone';
import {
    PRODUCTION_SERVICES_IDS,
    TESTING_SERVICES_IDS,
    ANY_COACH,
    IS_PROD,
    FEATURE_FLAG_NAMES,
} from '../config';
import css from './SchedulingForm.scss';
import SharedCoachingDate from './SharedCoachingDate';

// const CONFIRMATION = {
//     No: 2,
//     Yes: 1,
// };
const DATE_FORMAT = 'yyyy-MM-dd';
const SESSION_DURATION = '30';
const languages = [
    {
        key: 'english',
        value: 'en-US',
    },
    {
        key: 'spanish',
        value: 'es-US',
    },
];

const SchedulingForm = () => {
    const {t} = useTransLoader('schedulingForm');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {selectedCountry, selectedLang, changeLanguage} = useI18nContext();
    const {sharedCoaching} = useSelector(state => state.scheduler);
    const {
        hasSessionInPastYear,
        appointmentTypesInfo,
        firstName,
        lastName,
        email,
        phones,
    } = useSelector(state => state.userData.userData);
    const {token, uuid} = useSelector(state => state.userData.auth);
    const [isSharedCoaching, setIsSharedCoaching] = useState(false);
    const [disableScheduleButton, setDisableScheduleButton] = useState(false);
    const [selectedTimezone, setSelectedTimezone] = useState(
        defaultTimeZone(selectedCountry, selectedLang)
    );
    const [APIError, setAPIError] = useState();
    const [rankedProgram, setRankedProgram] = useState(null);
    const [serviceId, setServiceId] = useState(null);
    const [hasPastSession, setHasPastSession] = useState(false);
    const [selectedAnyCoach, setSelectedAnyCoach] = useState(false);
    const [availableDatesData, setAvailableDatesData] = useState(null);
    const [availableDates, setAvailableDates] = useState([]);
    const [initialMonth, setInitialMonth] = useState();
    const [filteredTimeSlots, setFilteredTimeSlots] = useState(null);
    const [availableTimes, setAvailableTimes] = useState([]);
    const [timeSlotsData, setTimeSlotsData] = useState(null);
    const [selectedTimeSlotData, setSelectedTimeSlotData] = useState(null);
    const [clientObject, setClientObject] = useState(null);
    const [locationObject, setLocationObject] = useState(null);
    const [reasonObject, setReasonObject] = useState(null);
    const [commentFieldObject, setCommentFieldObject] = useState(null);
    const [coachesData, setCoachesData] = useState(null);
    const [listOfCoachNames, setListOfCoachNames] = useState([]);
    const [selectedCoachId, setSelectedCoachId] = useState(null);
    const [coachId, setCoachId] = useState(null);
    const [notes, setNotes] = useState('');
    const [timeKey, setTimeKey] = useState(uuidv4());
    const returnSubDomain = Arg('returnSubDomain');
    const returnPath = Arg('returnPath');
    const oneApp =
        Arg('oneapp') ||
        Arg('ccmOneApp') ||
        window.location.hostname.includes('teladoc');
    const serviceTypesIds = IS_PROD
        ? PRODUCTION_SERVICES_IDS
        : TESTING_SERVICES_IDS;
    const TIMETAP_CCM_LOCATION_ID = process.env.TIMETAP_CCM_LOCATION_ID;
    const [enableNewUi, setEnableNewUi] = useState(false); // TODO: Remove this feature flag
    const {featureFlags} = useFeatureFlagContext();
    const newUi = Arg('enableNewUi');
    const TRUE = newUi === true;
    const timezoneChange = timezone => {
        const timeZoneValues = getClientTimeZoneObject(
            selectedCountry,
            timezone.value
        );

        setAvailableTimes(
            availableTimes.map(timeSlot => {
                const formattedTime = new Date(
                    Number(timeSlot.value)
                ).toISOString();

                return {
                    label: localizedTime(
                        formattedTime,
                        timezone.value,
                        selectedLang
                    ),
                    value: timeSlot.value,
                };
            })
        );
        setSelectedTimezone(timezone);

        clientObject.timeZone.timeZoneCode = timeZoneValues?.timeZoneCode;
        clientObject.timeZone.timeZoneDesc = timeZoneValues?.timeZoneDesc;
        clientObject.timeZone.timeZoneId = timeZoneValues?.timeZoneId;
    };

    const setServiceTypeId = useCallback(() => {
        const programs = [];
        const RANKED_PROGRAMS = [
            'HEART_FAILURE',
            'DIABETES',
            'DIABETES_DEVICEFLEX',
            'PREDIABETES',
            'HYPERTENSION',
            'CHRONIC_KIDNEY_DISEASE',
            'WEIGHT_MANAGEMENT',
        ];

        if (appointmentTypesInfo) {
            appointmentTypesInfo.forEach(type => {
                programs.push(type.program);
            });
        }

        // https://teladocpa.sharepoint.com/:x:/s/CoachingTeamLibrary/Eb3t_Q5mDTdImG_2x3SzICIBtnU6l_ISELYoEquncE5r8w?e=m7iekB
        // i.e. If a member is enrolled in both DM and WM, they will be assigned a DM coach
        const topProgram = RANKED_PROGRAMS.find(program =>
            programs.includes(program)
        );

        setHasPastSession(hasSessionInPastYear);
        setRankedProgram(topProgram);
        setServiceId(
            serviceTypesIds[
                `${topProgram}${
                    !hasSessionInPastYear ? '_INITIAL_' : '_FOLLOWUP_'
                }${selectedLang === 'en-US' ? 'ENGLISH' : 'SPANISH'}`
            ]
        );
    }, [
        appointmentTypesInfo,
        hasSessionInPastYear,
        serviceTypesIds,
        selectedLang,
    ]);

    const createNewClientObject = async () => {
        const timeZoneValues = getClientTimeZoneObject(
            selectedCountry,
            selectedTimezone.value
        );
        const clientData = {
            allowWaitListText: false,
            cellPhone: phones[0]?.number,
            emailAddress: email,
            firstName,
            fullName: `${firstName} ${lastName}`,
            lastName,
            locale: selectedLang,
            status: 'ACTIVE',
            timeZone: {
                timeZoneCode: timeZoneValues.timeZoneCode,
                timeZoneDesc: timeZoneValues.timeZoneDesc,
                timeZoneId: timeZoneValues.timeZoneId,
                visible: true,
            },
        };

        try {
            const data = await TimeTap.createNewClientObject(token, clientData);

            setClientObject(data);
        } catch (error) {
            setAPIError(error);
        }

        return;
    };

    const getClientObject = useCallback(() => {
        TimeTap.getClientObject(token, email)
            .then(data => {
                // Member is already in TimeTap database
                if (data?.length > 1) {
                    setClientObject(data[0]);
                }
                // Member needs to be added in the TimeTap database
                else if (data?.length === 1 && data[0]?.clientId === -1) {
                    createNewClientObject();
                }
            })
            .catch(error => {
                setAPIError(error);
            });

        return;
    }, [token, email]); // eslint-disable-line react-hooks/exhaustive-deps

    const getCommentFieldObject = useCallback(() => {
        const schedulerPreferenceFieldDefnId = IS_PROD ? '1463330' : '1521652';

        TimeTap.getCommentFieldObject(token, schedulerPreferenceFieldDefnId)
            .then(data => {
                setCommentFieldObject(data);
            })
            .catch(error => {
                setAPIError(error);
            });

        return;
    }, [token]);

    const getLocationObject = useCallback(() => {
        TimeTap.getLocationObject(token, TIMETAP_CCM_LOCATION_ID)
            .then(data => {
                setLocationObject(data);
            })
            .catch(error => {
                setAPIError(error);
            });

        return;
    }, [token, TIMETAP_CCM_LOCATION_ID]);

    const getReasonObject = useCallback(() => {
        TimeTap.getReasonObject(token, serviceId)
            .then(data => {
                setReasonObject(data);
            })
            .catch(error => {
                setAPIError(error);
            });

        return;
    }, [token, serviceId]);

    const getCoaches = useCallback(() => {
        TimeTap.getStaff(token, serviceId, TIMETAP_CCM_LOCATION_ID).then(
            data => {
                setCoachesData(data);

                const coachNames = [];

                coachNames.push({
                    label: t('sessionInfo.anyCoach'),
                    value: ANY_COACH,
                });

                data?.forEach(coach => {
                    coachNames.push({
                        label: coach.internalDisplayName,
                        value: coach.professionalId.toString(),
                    });
                });

                setListOfCoachNames(coachNames);
            }
        );

        return;
    }, [token, serviceId, TIMETAP_CCM_LOCATION_ID, t]);

    const getAllCoachesAvailableDates = useCallback(() => {
        const dateInstance = new Date();
        const requests = [];
        const monthsAhead = 2;

        for (let i = 0; i <= monthsAhead; i++) {
            let startDate;
            const endDate = format(
                endOfMonth(add(new Date(), {months: i})),
                DATE_FORMAT
            );

            if (i === 0) {
                startDate = format(new Date(), DATE_FORMAT);
            } else {
                startDate = format(
                    startOfMonth(add(new Date(), {months: i})),
                    DATE_FORMAT
                );
            }

            requests.push(
                TimeTap.getAllCoachesAvailableDates(
                    token,
                    serviceId,
                    startDate,
                    endDate,
                    TIMETAP_CCM_LOCATION_ID
                )
            );
        }

        Promise.all(requests)
            .then(data => {
                const availableDatesResponse = data
                    ?.flat()
                    ?.filter(availableDate => availableDate.timeSlots.length);
                const parsedDates = availableDatesResponse.map(({date}) => {
                    return parse(date, DATE_FORMAT, new Date());
                });
                const formattedDates = parsedDates.map(date => {
                    return format(date, 'MM/dd/yyyy');
                });

                // Getting time slots for current day to see if there are any available that
                // are 2 hours in advance in order to mark this day in the calendar as available
                if (format(dateInstance, 'MM/dd/yyyy') === formattedDates[0]) {
                    const startTime = addHours(new Date(), 2).getTime();
                    const filteredTimes =
                        availableDatesResponse[0]?.timeSlots.filter(
                            timeSlot =>
                                timeSlot.staffStartDateTimeUTC >= startTime
                        );

                    if (isEmpty(filteredTimes)) {
                        // Remove current day because there's no available times that are 2 hours in advance
                        formattedDates.shift();
                    } else {
                        setFilteredTimeSlots(filteredTimes);
                    }
                }

                setAvailableDatesData(availableDatesResponse);
                setAvailableDates(formattedDates);

                if (parsedDates.length) {
                    setInitialMonth(format(parsedDates[0], 'MM/dd/yyyy'));
                }
            })
            .catch(error => {
                setAPIError(error);
            });

        return;
    }, [serviceId, token, TIMETAP_CCM_LOCATION_ID]);

    // Trigerring a re-render for the time selection so that it shows the placeholder text for the new day selected
    const renderTimeSelection = () => {
        setTimeKey(uuidv4());
        setAvailableTimes([]);
    };

    const onLanguageChange = ({target}) => {
        const locale = target.value;
        // Resetting these values to reset the calendar and time selection until new dates are fetched for the new serviceId

        setInitialMonth(null);
        setAvailableDates([]);

        renderTimeSelection();

        setServiceId(
            serviceTypesIds[
                `${rankedProgram}${
                    !hasPastSession ? '_INITIAL_' : '_FOLLOWUP_'
                }${locale === 'en-US' ? 'ENGLISH' : 'SPANISH'}`
            ]
        );

        changeLanguage(locale);
    };

    const onPastSessionChange = hasHadPastSession => {
        // Resetting these values to reset the calendar and time selection until new dates are fetched for the new serviceId
        setInitialMonth(null);
        setAvailableDates([]);
        renderTimeSelection();

        setServiceId(
            serviceTypesIds[
                `${rankedProgram}${
                    !hasHadPastSession ? '_INITIAL_' : '_FOLLOWUP_'
                }${selectedLang === 'en-US' ? 'ENGLISH' : 'SPANISH'}`
            ]
        );
        setHasPastSession(hasHadPastSession === t('myStrength.yes'));
    };

    const getLastSessionData = useCallback(() => {
        TimeTap.getLastSessionData(token, clientObject.clientId).then(data => {
            const professionalId = data?.staff?.professionalId?.toString();

            if (listOfCoachNames.find(({value}) => value === professionalId)) {
                setSelectedCoachId(professionalId);
            }
            // There is an edge case where the coach from the last session isn't in
            // the list of coach names because i.e. they don't have availability in the
            // next 3 months, so we pre-select the "Any Coach" option in the dropdown
            else {
                setSelectedAnyCoach(true);
                setSelectedCoachId(ANY_COACH);
                getAllCoachesAvailableDates();
            }
        });

        return;
    }, [token, clientObject, listOfCoachNames, getAllCoachesAvailableDates]);

    const getCoachAvailableDates = useCallback(() => {
        const dateInstance = new Date();
        const requests = [];
        const monthsAhead = 2;

        for (let i = 0; i <= monthsAhead; i++) {
            const year = format(add(dateInstance, {months: i}), 'yyyy');
            const month = format(add(dateInstance, {months: i}), 'MM');

            requests.push(
                TimeTap.getCoachAvailableDates(
                    token,
                    year,
                    month,
                    selectedCoachId,
                    serviceId,
                    SESSION_DURATION,
                    TIMETAP_CCM_LOCATION_ID
                )
            );
        }

        Promise.all(requests)
            .then(data => {
                const availableDatesResponse = data?.flat();
                const formattedDates = availableDatesResponse.map(
                    ({month, day, year}) => {
                        const date = month
                            .toString()
                            .concat(
                                '/',
                                day.toString().concat('/', year.toString())
                            );
                        const parsedDate = parse(
                            date,
                            'M/d/yyyy',
                            dateInstance
                        );

                        return format(parsedDate, 'MM/dd/yyyy');
                    }
                );

                // Getting time slots for current day to see if there are any available that
                // are 2 hours in advance in order to mark this day in the calendar as available
                if (format(new Date(), 'MM/dd/yyyy') === formattedDates[0]) {
                    TimeTap.getCoachAvailableTimes(
                        token,
                        dateInstance.getFullYear(),
                        format(dateInstance, 'MM'),
                        format(dateInstance, 'dd'),
                        selectedCoachId,
                        serviceId,
                        SESSION_DURATION,
                        TIMETAP_CCM_LOCATION_ID
                    ).then(timesData => {
                        if (!isEmpty(timesData)) {
                            const startTime = addHours(new Date(), 2).getTime();
                            const filteredTimes = timesData.filter(
                                // eslint-disable-next-line max-nested-callbacks
                                timeSlot =>
                                    timeSlot.staffStartDateTimeUTC >= startTime
                            );

                            if (isEmpty(filteredTimes)) {
                                // Remove current day because there's no available times that are 2 hours in advance
                                formattedDates.shift();
                            } else {
                                setFilteredTimeSlots(filteredTimes);
                            }
                        }
                    });
                }

                setAvailableDates(formattedDates);

                if (formattedDates.length) {
                    setInitialMonth(formattedDates[0]);
                }
            })
            .catch(error => {
                setAPIError(error);
            });

        return;
    }, [token, selectedCoachId, serviceId, TIMETAP_CCM_LOCATION_ID]);

    const onCoachSelected = coachIdValue => {
        // Trigerring a re-render for the calendar so that it shows the
        // placeholder text instead of the selected day for the previous coach
        setInitialMonth(null);
        setAvailableDates([]);

        renderTimeSelection();

        if (coachIdValue === ANY_COACH) {
            setSelectedAnyCoach(true);
            getAllCoachesAvailableDates();
        } else {
            setSelectedAnyCoach(false);
            setSelectedCoachId(coachIdValue);
        }
    };

    const onDaySelected = date => {
        const dateInstance = new Date(date);

        if (availableTimes) {
            renderTimeSelection();
        }

        if (selectedAnyCoach) {
            let timeSlots;

            // For the current day, we already filtered the time slots in getAllCoachesAvailableDates()
            if (dateInstance.toDateString() === new Date().toDateString()) {
                timeSlots = filteredTimeSlots;
            } else {
                const formattedDate = format(dateInstance, DATE_FORMAT);
                const dateData = availableDatesData.filter(
                    availableDate => availableDate.date === formattedDate
                );

                timeSlots = dateData[0].timeSlots;
            }

            setTimeSlotsData(timeSlots);
            setAvailableTimes(
                timeSlots.map(timeSlot => {
                    const convertedDate = new Date(
                        timeSlot.staffStartDateTimeUTC
                    ).toISOString();

                    return {
                        label: localizedTime(
                            convertedDate,
                            selectedTimezone.value,
                            selectedLang
                        ),
                        value: timeSlot.staffStartDateTimeUTC.toString(),
                    };
                })
            );
        } else {
            const splitDate = date.split('/');
            const month = splitDate[0];
            const day = splitDate[1];
            const year = splitDate[2];

            TimeTap.getCoachAvailableTimes(
                token,
                year,
                month,
                day,
                selectedCoachId,
                serviceId,
                SESSION_DURATION,
                TIMETAP_CCM_LOCATION_ID
            )
                .then(data => {
                    let timeSlots;

                    // For the current day, we already filtered the time slots in getCoachAvailableDates()
                    if (
                        dateInstance.toDateString() ===
                        new Date().toDateString()
                    ) {
                        timeSlots = filteredTimeSlots;
                    } else {
                        timeSlots = data;
                    }

                    setTimeSlotsData(timeSlots);
                    setAvailableTimes(
                        timeSlots?.map(timeSlot => {
                            const convertedDate = new Date(
                                timeSlot.staffStartDateTimeUTC
                            ).toISOString();

                            return {
                                label: localizedTime(
                                    convertedDate,
                                    selectedTimezone.value,
                                    selectedLang
                                ),
                                value: timeSlot.staffStartDateTimeUTC.toString(),
                            };
                        })
                    );
                })
                .catch(error => {
                    setAPIError(error);
                });
        }
    };

    const onTimeSelected = time => {
        const timeSlotData = timeSlotsData.filter(
            timeSlot => timeSlot.staffStartDateTimeUTC === Number(time.value)
        );

        setSelectedTimeSlotData(timeSlotData[0]);

        if (selectedAnyCoach) {
            const timeSlotInfo = timeSlotData[0].units;

            // More than 1 coach has the time slot available
            if (timeSlotInfo.length > 1) {
                const requests = [];

                timeSlotInfo.forEach(timeSlot => {
                    requests.push(
                        TimeTap.getCoachAppointmentCount(
                            token,
                            timeSlot.professionalId
                        )
                    );
                });

                Promise.all(requests).then(data => {
                    // Finding which coach has the least amount of appointments
                    const coachInfo = data.reduce((prev, curr) => {
                        if (prev.count === curr.count) {
                            return 'sameCount';
                        }

                        return prev.count < curr.count ? prev : curr;
                    });

                    if (coachInfo === 'sameCount') {
                        // Picking a coach randomly
                        setCoachId(
                            timeSlotInfo[
                                Math.floor(Math.random() * timeSlotInfo.length)
                            ].professionalId
                        );
                    } else {
                        setCoachId(coachInfo.coachId);
                    }
                });
            } else {
                setCoachId(timeSlotInfo[0].professionalId);
            }
        }
    };

    const scheduleAppointment = () => {
        setDisableScheduleButton(true);

        if (isSharedCoaching) {
            delete sharedCoaching.uuid;

            SharedCoaching.submitSharedCoaching(
                {
                    ...sharedCoaching,
                    reason: 1,
                    message: notes,
                },
                uuid,
                token
            ).then(() => {
                navigate(
                    enableNewUi
                        ? 'v2//confirmation?enableNewUi=true&view=fullview'
                        : '/confirmation'
                );
            });
        } else {
            const coachData = coachesData.filter(
                coach =>
                    coach.professionalId ===
                    (coachId || Number(selectedCoachId))
            );
            const timeZoneValues = getClientTimeZoneObject(
                selectedCountry,
                selectedTimezone.value
            );

            clientObject.timeZone.timeZoneCode = timeZoneValues?.timeZoneCode;
            clientObject.timeZone.timeZoneDesc = timeZoneValues?.timeZoneDesc;
            clientObject.timeZone.timeZoneId = timeZoneValues?.timeZoneId;
            commentFieldObject.value = notes;

            const appointmentData = {
                client: clientObject,
                clientEndDate: selectedTimeSlotData.clientEndDate,
                clientEndTime: selectedTimeSlotData.clientEndTime,
                clientReminderHours: 24,
                clientStartDate: selectedTimeSlotData.clientStartDate,
                clientStartTime: selectedTimeSlotData.clientStartTime,
                endDate: selectedTimeSlotData.staffEndDate,
                endTime: selectedTimeSlotData.staffEndTime,
                fields: [commentFieldObject],
                location: locationObject,
                reason: reasonObject,
                remindStaffSmsHrs: 0,
                remindClientSmsHrs: 24,
                sendConfirmationToClient: true,
                sendConfirmationToStaff: true,
                staff: coachData[0],
                staffReminderHours: 24,
                startDate: selectedTimeSlotData.staffStartDate,
                startTime: selectedTimeSlotData.staffStartTime,
                status: 'OPEN',
            };

            TimeTap.createAppointmentNonProxyCcmOnly(token, appointmentData)
                .then(data => {
                    dispatch({
                        type: 'APPOINTMENT_DETAILS',
                        payload: {
                            startDate: new Date(data?.startDateTimeUTC),
                            endDate: new Date(data?.endDateTimeUTC),
                            timezone: selectedTimezone.value,
                            type: data?.reason?.reasonDesc,
                            coach: data?.staff?.internalDisplayName,
                        },
                    });

                    dispatch({
                        type: 'VIEW',
                        payload: {
                            enableNewUi: true,
                            view: 'fullview',
                            calendarId: data?.calenderId,
                        },
                    });

                    MixpanelUtils.track({
                        event: 'coaching.scheduling.success',
                    });

                    navigate(
                        enableNewUi
                            ? '/v2/confirmation?enableNewUi=true&view=fullview'
                            : '/confirmation'
                    );
                })
                .catch(err => {
                    setAPIError(err);
                    MixpanelUtils.track({
                        event: 'coaching.scheduling.failure',
                        properties: {
                            'Error Code': err.status,
                        },
                    });
                });
        }

        // Check if user has schedule a coaching session before.
        SharedCoaching.getLaunchpadStatus(token)
            .then(({items}) => {
                const coachStatus = items?.find(
                    item => item.name === 'COACHING'
                );

                // User has not schedule a session
                if (!coachStatus.completed) {
                    const markItem = {
                        item: {name: 'COACHING', completed: true},
                    };

                    SharedCoaching.markLaunchpadStatus(token, markItem);
                }
            })
            .catch(err => {
                // silent
            });
    };

    const redirectToOneApp = () => {
        return CommonUtils.redirectToOneApp(
            IS_PROD,
            returnSubDomain,
            returnPath
        );
    };

    useEffect(() => {
        SharedCoaching.coachingInfo(token, uuid)
            .then(data => setIsSharedCoaching(data.sharedCoaching))
            .catch(err => {
                dispatch(UserInfoAPI.incorrectToken);
            });
    }, [token, uuid]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (token) {
            setServiceTypeId();
        }
    }, [token, setServiceTypeId]);

    useEffect(() => {
        if (token && serviceId) {
            getClientObject();
            getLocationObject();
            getReasonObject();
            getCommentFieldObject();
            getCoaches();
        }
    }, [
        token,
        serviceId,
        getClientObject,
        getLocationObject,
        getReasonObject,
        getCommentFieldObject,
        getCoaches,
    ]);

    useEffect(() => {
        if (token && serviceId) {
            if (clientObject && !isEmpty(listOfCoachNames)) {
                getLastSessionData();
            }
        }
    }, [token, serviceId, clientObject, listOfCoachNames, getLastSessionData]);

    useEffect(() => {
        if (token && serviceId) {
            if (selectedCoachId && !selectedAnyCoach) {
                getCoachAvailableDates();
            }
        }
    }, [
        token,
        serviceId,
        selectedCoachId,
        selectedAnyCoach,
        getCoachAvailableDates,
    ]);

    useEffect(() => {
        setEnableNewUi(
            CommonUtils.isFeatureEnabled(
                featureFlags,
                FEATURE_FLAG_NAMES.enableNewUi,
                oneApp
            )
        );
        setEnableNewUi(TRUE);
    }, [featureFlags, oneApp, newUi, TRUE]);

    useEffect(() => {
        if (APIError) {
            MixpanelUtils.track({
                event: 'coaching.scheduling.error',
                properties: {
                    'Error Code': APIError.status,
                },
            });
        }
    }, [APIError]);

    useEffect(() => {
        MixpanelUtils.track({
            event: 'coaching.scheduling.viewed',
        });
    }, []);

    return (
        <div>
            <AcuityError isOpen={APIError} toggleModal={setAPIError} />
            <Form className={css.root} onSubmit={() => scheduleAppointment()}>
                <div className={css.header}>
                    <div className={css.logoWrapper}>
                        <Image
                            alt="Teladoc logo"
                            cloudinaryImageId={
                                oneApp
                                    ? 'coach/teladoc_health_logo_ukf9ne'
                                    : 'coach/livongo_by_teladoc_logo_t4rntd'
                            }
                            format="jpg"
                            width={140}
                            height={40}
                        />
                    </div>
                </div>
                <h2>{t('title')}</h2>
                {!isSharedCoaching ? (
                    <>
                        <div>
                            <h3 className={css.sectionTitle}>
                                {t('contactInfo')}
                            </h3>
                            <UserInfo />
                            <p className={css.logIn}>
                                <a
                                    href={
                                        oneApp
                                            ? redirectToOneApp()
                                            : `${process.env.MEMBER_PORTAL_URL}/profile-preferences/account-settings`
                                    }
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {t('login')}
                                </a>{' '}
                                {t('loginReason')}
                            </p>
                            <HorizontalRule spaceTop={24} spaceBottom={24} />
                        </div>
                        <h3 className={css.sectionTitle}>
                            {t('sessionInfo.heading')}
                        </h3>
                        <RadioGroup
                            id="language"
                            name="language"
                            onChange={onLanguageChange}
                            label={
                                <Label required>
                                    {t('sessionInfo.language')}
                                </Label>
                            }
                        >
                            {languages.map(({key, value: option}) => {
                                return (
                                    <Radio
                                        key={key}
                                        id={`language-${key}`}
                                        value={option}
                                        label={
                                            <Label>
                                                {t(`sessionInfo.${key}`)}
                                            </Label>
                                        }
                                        defaultChecked={option === selectedLang}
                                    />
                                );
                            })}
                        </RadioGroup>
                        <Select
                            key={hasSessionInPastYear}
                            id="pastSession"
                            label={
                                <Label>{t('sessionInfo.pastSession')}</Label>
                            }
                            items={[
                                {
                                    label: t('myStrength.no'),
                                    value: t('myStrength.no'),
                                },
                                {
                                    label: t('myStrength.yes'),
                                    value: t('myStrength.yes'),
                                },
                            ]}
                            defaultValue={
                                hasSessionInPastYear
                                    ? t('myStrength.yes')
                                    : t('myStrength.no')
                            }
                            onChange={({value}) => onPastSessionChange(value)}
                        />
                        <Select
                            key={selectedCoachId}
                            id="coaches"
                            name="coaches"
                            label={<Label>{t('sessionInfo.coach')}</Label>}
                            placeholder={t('sessionInfo.coachPlaceholder')}
                            items={listOfCoachNames}
                            defaultValue={selectedCoachId}
                            onChange={coach => onCoachSelected(coach.value)}
                            required
                        />
                        <div>
                            <Select
                                id="timeZone"
                                name="timeZone"
                                label={
                                    <Label>{t('sessionInfo.timezone')}</Label>
                                }
                                placeholder={t(
                                    'sessionInfo.timezonePlaceholder'
                                )}
                                defaultValue={t(selectedTimezone?.value)}
                                items={timeZones[selectedCountry].map(
                                    ({value, label}) => ({
                                        value,
                                        label: t(`timeZone.${label}`),
                                    })
                                )}
                                onChange={tz => timezoneChange(tz)}
                                required
                            />
                            <DatePicker
                                key={initialMonth}
                                id="date"
                                name="date"
                                classNameItem={css.dateInput}
                                calendar={{
                                    disablePast: true,
                                    disableBefore: initialMonth,
                                    initialMonth,
                                    available: availableDates,
                                }}
                                disabled={!initialMonth}
                                label={<Label>{t('sessionInfo.date')}</Label>}
                                onChange={date => onDaySelected(date)}
                                required
                            />
                            <Select
                                key={timeKey}
                                id="time"
                                name="time"
                                disabled={isEmpty(availableTimes)}
                                label={<Label>{t('sessionInfo.time')}</Label>}
                                items={availableTimes}
                                onChange={time => onTimeSelected(time)}
                                placeholder={t('sessionInfo.timePlaceholder')}
                                required
                            />
                            <p>{t('sessionInfo.helperDescription')}</p>
                        </div>
                    </>
                ) : (
                    <SharedCoachingDate />
                )}
                <HorizontalRule spaceTop={24} spaceBottom={24} />
                <TextArea
                    id="note"
                    name="note"
                    maxLength={255}
                    showCounter
                    placeholder={t('sessionInfo.notesPlaceholder')}
                    label={
                        <Label className={css.sectionTitle}>
                            {t('sessionInfo.notes')}
                        </Label>
                    }
                    onChange={e => setNotes(e.target.value)}
                />
                <Button
                    type="submit"
                    variant="primary"
                    className={css.confirmButton}
                    disabled={
                        disableScheduleButton ||
                        (!isSharedCoaching
                            ? isEmpty(selectedTimeSlotData)
                            : false)
                    }
                >
                    {t('sessionInfo.submit')}
                </Button>
            </Form>
        </div>
    );
};

export default SchedulingForm;
