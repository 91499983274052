import PropTypes from 'prop-types';
import {useRef, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {cnb} from 'cnbuilder';
import addMinutes from 'date-fns/addMinutes';
import parseISO from 'date-fns/parseISO';
import {utcToZonedTime} from 'date-fns-tz';
import isEmpty from 'lodash/isEmpty';
import Button from '@teladoc/pulse/ui/Button';
import Accordion from '@teladoc/pulse/ui/Accordion';
import IconAlertActive from '@teladoc/pulse/icons/alert-active.svg';
import IconCheckActive from '@teladoc/pulse/icons/check-active.svg';
import Arg from '@livongo/arg';
import useTransLoader from 'i18n/use-trans-loader';
import {goToMyStrength} from '../utils/myStrength';
import Card from '../common/Card';
import CommonUtils from '../utils/common-utils';
import Loader from '../common/loader/Loader';
import TimeTap from '../utils/TimeTap';
import MixpanelUtils from '../common/mix-panel';
import ConfirmationDetails from './ConfirmationDetails';
import CancelModal from './cancelmodal/CancelModal';
import FullView from './view/FullView';
import SmallView from './view/SmallView';
import css from './SessionDetails.scss';

const SessionDetails = ({
    showHeading,
    showCancelButton,
    showAccordion,
    calendarId,
    appointmentData,
    dontShowLoader,
}) => {
    const {t} = useTransLoader('confirmation');
    const {type, datetime, startDate, endDate, duration, timezone} =
        appointmentData;

    const isWebSDK = Arg('oneAppSdk');
    const isMobile = Arg('mobile');
    const contentRef = useRef();
    const {token} = useSelector(state => state.userData.auth);
    const [isLoading, setIsLoading] = useState(true);
    const OPEN = 'OPEN';
    const {sharedCoaching} = useSelector(state => state.scheduler);
    const isSharedCoaching = !isEmpty(sharedCoaching);
    const [showCancelModal, setShowCancelModal] = useState(false);
    const [allowAccordion, setAllowAccordion] = useState(!isMobile);
    const [coachingSession, setCoachingSession] = useState(null);
    const [apiSessionToken, setApiSessionToken] = useState(token);
    const [successfullyCancelled, setSuccessfullyCancelled] = useState(false);
    const isMentalHealth = Arg('referrer')?.includes('mystrength');
    const rootClass = cnb(css.root);

    const eventName = isSharedCoaching
        ? 'Shared Coaching'
        : isMentalHealth
        ? type.replace('[TEST]', '').replace('(INT)', '').trim()
        : type;
    const utcStartDate = isSharedCoaching
        ? new Date(sharedCoaching?.preferredTimeWindow[0])
        : isMentalHealth
        ? parseISO(datetime)
        : startDate;
    const utcEndDate = isSharedCoaching
        ? new Date(sharedCoaching?.preferredTimeWindow[1])
        : isMentalHealth
        ? addMinutes(utcStartDate, parseInt(duration || 0, 10))
        : endDate;
    const localStartDate = utcStartDate
        ? utcToZonedTime(utcStartDate, timezone || 'America/Chicago')
        : null;
    const localEndDate = utcStartDate
        ? utcToZonedTime(utcEndDate, timezone || 'America/Chicago')
        : null;
    const showCancel = () => {
        setShowCancelModal(!showCancelModal);
    };

    const openOutLookCalender = () => {
        if (localStartDate) {
            CommonUtils.openOutLookCalender({
                end: localEndDate,
                start: localStartDate,
                name: eventName,
                alreadyFormtted: false,
                mwUrl: process.env.MIDDLEWARE_URL,
            });
        } else {
            CommonUtils.openOutLookCalender({
                start: coachingSession?.clientStartDateTimeUTC,
                end: coachingSession?.clientEndDateTimeUTC,
                name: coachingSession?.reason?.reasonDesc,
                alreadyFormtted: true,
                mwUrl: process.env.MIDDLEWARE_URL,
            });
        }
        MixpanelUtils.track({
            event: 'coaching.scheduling.add.to.calendar.clicked',
            properties: {
                type: 'ical_outlook',
                source: 'scheduling',
            },
        });
    };

    const openGoogleCalendar = () => {
        if (localStartDate) {
            CommonUtils.openGoogleCalendar({
                start: localStartDate,
                end: localEndDate,
                name: eventName,
            });
        } else {
            CommonUtils.openGoogleCalendar({
                start: coachingSession?.clientStartDateTimeUTC,
                end: coachingSession?.clientEndDateTimeUTC,
                name: coachingSession?.reason?.reasonDesc,
            });
        }
        MixpanelUtils.track({
            event: 'coaching.scheduling.add.to.calendar.clicked',
            properties: {
                type: 'google',
                source: 'scheduling',
            },
        });
    };

    const callMixPanel = () => {
        MixpanelUtils.track({
            event: 'coaching.scheduling.how.to.prepare.clicked',
        });
    };

    useEffect(() => {
        window.scrollTo(0, 0);

        if (isWebSDK) {
            const sendHeightToParent = () => {
                const message = {height: document.body.scrollHeight};

                window.top.postMessage(message, '*');
            };

            if (window.ResizeObserver) {
                const resizeObserver = new ResizeObserver(entries =>
                    entries.forEach(entry => sendHeightToParent())
                );

                resizeObserver.observe(document.body);
            }
        }
    }, [isWebSDK]);

    useEffect(() => {
        const getCoachSession = async () => {
            try {
                const data = await TimeTap.getCoachingSession(
                    token,
                    calendarId
                );

                setApiSessionToken(token);
                setCoachingSession(data);
                setIsLoading(false);
            } catch {
                // do nothing
            }
        };

        if (calendarId && token) {
            getCoachSession();
        }
    }, [calendarId, token]);

    useEffect(() => {
        if (!isMobile) {
            MixpanelUtils.track({
                event: 'coaching.scheduling.confirmation.viewed',
            });
        }

        if (isMobile) {
            MixpanelUtils.track({
                event: 'coaching.next.session.viewed',
            });
        }
    }, [isMobile]);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setAllowAccordion(true);
        }, 200);

        return () => clearTimeout(timeout);
    }, [allowAccordion]);

    useEffect(() => {
        const timeout = setTimeout(() => {
            contentRef?.current?.focus();
        }, 300);

        return () => clearTimeout(timeout);
    }, []);

    useEffect(() => {
        document.title = `${t('sessionScheduled')} | Teladoc Health`;
    }, [t]);

    if (!dontShowLoader && isMobile && isLoading) {
        return <Loader />;
    }

    return (
        <div className={css.outerFrame}>
            {showCancelModal && calendarId && (
                <CancelModal
                    setShowCancelModal={showCancel}
                    calendarId={calendarId}
                    apiSessionToken={apiSessionToken}
                    setSuccessfullyCancelled={setSuccessfullyCancelled}
                    isMobile={isMobile}
                />
            )}
            <Card>
                <div className={rootClass}>
                    <div>
                        {showHeading && (
                            <div className={css.temp}>
                                <h1
                                    className={
                                        isMobile
                                            ? css.newSessionScheduledTitleMobile
                                            : css.newSessionScheduledTitle
                                    }
                                    ref={contentRef}
                                    tabIndex={-1}
                                >
                                    {t('sessionScheduled')}
                                </h1>
                            </div>
                        )}

                        {showHeading && (
                            <div className={css.temp}>
                                <div
                                    className={css.newHeaderDetails}
                                    aria-label={t('sessionScheduleHeader')}
                                >
                                    {t('sessionScheduleHeader')}
                                </div>
                            </div>
                        )}
                        <div
                            className={
                                isMobile
                                    ? css.newSessionScheduledTitleMobile
                                    : css.newSessionScheduledTitle
                            }
                        >
                            <h2 className={css.temp}>
                                {t('sessionScheduleDetails')}
                            </h2>
                        </div>
                        <div>
                            {isMobile ? (
                                <SmallView
                                    calendarId={calendarId}
                                    appointmentData={appointmentData}
                                    coachingSession={coachingSession}
                                />
                            ) : (
                                <FullView
                                    calendarId={calendarId}
                                    appointmentData={appointmentData}
                                    coachingSession={coachingSession}
                                />
                            )}
                        </div>
                        <div className={css.topspace}>
                            {!successfullyCancelled &&
                                coachingSession?.status === OPEN &&
                                showCancelButton &&
                                calendarId && (
                                    <div className={css.newDetail}>
                                        <Button
                                            type="submit"
                                            variant="secondary"
                                            className={
                                                isMobile
                                                    ? css.mobileCancelButton
                                                    : css.CancelButton
                                            }
                                            role="link"
                                            onClick={showCancel}
                                        >
                                            {t('cancelSession')}
                                        </Button>
                                    </div>
                                )}
                            {calendarId && coachingSession?.status !== OPEN && (
                                <div className={css.container}>
                                    <div>
                                        <div className={css.imageIcon}>
                                            <IconAlertActive
                                                className={css.iconFill}
                                                height={24}
                                            />
                                        </div>
                                    </div>
                                    <div className={css.newDetail}>
                                        {t('cancelSessionMessage')}
                                    </div>
                                </div>
                            )}
                            {calendarId && successfullyCancelled && (
                                <div className={css.container}>
                                    <div>
                                        <div className={css.imageIcon}>
                                            <IconCheckActive
                                                className={css.iconFill}
                                                height={24}
                                            />
                                        </div>
                                    </div>
                                    <div className={css.newDetail}>
                                        {t('cancelSuccess')}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div
                        key={isMobile}
                        className={cnb(css.panel, {
                            [css.onMobileDevice]: isMobile,
                        })}
                    >
                        {allowAccordion && (isMobile || showAccordion) && (
                            <Accordion
                                className={css.panel}
                                items={[
                                    {
                                        content: <ConfirmationDetails />,
                                        isOpen: false,
                                        trigger: (
                                            <h2
                                                className={
                                                    css.fullSessionDetails
                                                }
                                            >
                                                {t(
                                                    isMobile
                                                        ? 'sessionScheduledFullSession'
                                                        : 'sessionScheduledFullSessionDetails'
                                                )}
                                            </h2>
                                        ),
                                        onToggle: callMixPanel,
                                    },
                                ]}
                            />
                        )}
                    </div>
                </div>
            </Card>

            {!showAccordion && !isMobile && (
                <Card>
                    <div className={css.panel}>
                        <div>
                            <div className={css.secondHeaderMargin}>
                                <p className={css.newHeader}>
                                    {t('sessionScheduledFullSession')}
                                </p>
                            </div>
                            <div>
                                <ConfirmationDetails />
                            </div>
                        </div>
                    </div>
                </Card>
            )}
            <div>
                <div className={css.outerAction}>
                    <div className={css.preferal}>
                        <p className={css.topspace}>{t('calendarBottom')}</p>
                    </div>
                </div>
                <div className={css.outerAction}>
                    <div
                        className={
                            isMobile
                                ? css.mobileCalendarButtons
                                : css.calendarButtons
                        }
                    >
                        {isMentalHealth && (
                            <Button
                                type="submit"
                                variant="primary"
                                className={css.doneButton}
                                role="link"
                                onClick={goToMyStrength}
                            >
                                {t('done')}
                            </Button>
                        )}
                        <Button
                            type="submit"
                            variant="secondary"
                            className={css.appleButton}
                            role="link"
                            onClick={() => {
                                openOutLookCalender();
                            }}
                        >
                            iCAL/Outlook
                        </Button>
                        <Button
                            type="submit"
                            variant="secondary"
                            className={css.googleButton}
                            role="link"
                            onClick={() => {
                                openGoogleCalendar();
                            }}
                        >
                            Google
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

SessionDetails.propTypes = {
    showHeading: PropTypes.bool,
    showCancelButton: PropTypes.bool,
    showAccordion: PropTypes.bool,
    calendarId: PropTypes.number,
    appointmentData: PropTypes.object,
    dontShowLoader: PropTypes.bool,
};

export default SessionDetails;
