import isEmpty from 'lodash/isEmpty';
import {useCallback, useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {add, differenceInMonths, parse, parseISO} from 'date-fns'; // eslint-disable-line no-restricted-imports
import addHours from 'date-fns/addHours';
import endOfMonth from 'date-fns/endOfMonth';
import startOfMonth from 'date-fns/startOfMonth';
import {format} from 'date-fns-tz';
import {useNavigate} from 'react-router-dom';
import {v4 as uuidv4} from 'uuid';
import {cnb} from 'cnbuilder';
import IconArrowLeftDefault from '@teladoc/pulse/icons/arrow-left-default.svg';
import IconInfoDefault from '@teladoc/pulse/icons/info-default.svg';
import Button from '@teladoc/pulse/ui/Button';
import Chip from '@teladoc/pulse/ui/Chip';
import DatePicker from '@teladoc/pulse/ui/DatePicker';
import Form from '@teladoc/pulse/ui/Form';
import HorizontalRule from '@teladoc/pulse/ui/HorizontalRule';
import Label from '@teladoc/pulse/ui/Label';
import Loader from '@teladoc/pulse/ui/Loader';
import Modal from '@teladoc/pulse/ui/Modal';
import TextArea from '@teladoc/pulse/ui/TextArea';
import Select from '@teladoc/pulse/ui/Select';
import {useI18nContext} from '@teladoc/pulse/ui/Context/i18n';
import Arg from '@livongo/arg';
import useTransLoader from 'i18n/use-trans-loader';
import AcuityError from '../ErrorMessages/AcuityError';
import {goToMyStrength} from '../utils/myStrength';
import {
    timeZones,
    defaultTimeZone,
    localizedTime,
    getClientTimeZoneObject,
} from '../utils/Timezone';
import {MH_MIXPANEL_PRODUCT} from '../config';
import UserInfoAPI from '../UserInfo/userInfo-api';
import TimeTap from '../utils/TimeTap';
import MixpanelUtils from '../common/mix-panel';
import css from './SchedulingFormMentalHealth.scss';
import '../index.scss';

const DATE_FORMAT = 'yyyy-MM-dd';
const SESSION_DURATION = '30';

const SchedulingFormMentalHealth = () => {
    const {t} = useTransLoader('schedulingForm');
    const {selectedCountry, selectedLang} = useI18nContext();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {token, uuid} = useSelector(state => state.userData.auth);
    const {firstName, lastName, email, phones} = useSelector(
        state => state.userData.userData
    );
    const [assistiveDevice, setAsstistiveDevice] = useState('No');
    const [videoCamera, setVideoCamera] = useState('Yes');
    const [internalUserId, setInternalUserId] = useState(null);
    const [APIError, setAPIError] = useState();
    const [selectedTimezone, setSelectedTimezone] = useState(
        defaultTimeZone(selectedCountry)
    );
    const [assignedCoachName, setAssignedCoachName] = useState('');
    const [selectedAssignedCoach, setSelectedAssignedCoach] = useState(true);
    const [selectedAnyCoach, setSelectedAnyCoach] = useState(false);
    const [availableDatesData, setAvailableDatesData] = useState(null);
    const [availableDates, setAvailableDates] = useState([]);
    const [initialMonth, setInitialMonth] = useState();
    const [filteredTimeSlots, setFilteredTimeSlots] = useState(null);
    const [availableTimes, setAvailableTimes] = useState([]);
    const [timeSlotsData, setTimeSlotsData] = useState(null);
    const [selectedTimeSlotData, setSelectedTimeSlotData] = useState(null);
    const [clientObject, setClientObject] = useState(null);
    const [locationObject, setLocationObject] = useState(null);
    const [reasonObject, setReasonObject] = useState(null);
    const [hasUpcomingSession, setHasUpcomingSession] = useState(false);
    const [commentFieldObject, setCommentFieldObject] = useState(null);
    const [assistiveDeviceFieldObject, setAsstistiveDeviceFieldObject] =
        useState(null);
    const [videoCameraFieldObject, setVideoCameraFieldObject] = useState(null);
    const [uuidFieldObject, setUuidFieldObject] = useState(null);
    const [coachesData, setCoachesData] = useState(null);
    const [coachId, setCoachId] = useState(null);
    const [notes, setNotes] = useState('');
    const [timeKey, setTimeKey] = useState(uuidv4());
    const [showSwitchingToNewCoachModal, setShowSwitchingToNewCoachModal] =
        useState(false);
    const isOneApp = Arg('oneAppSdk');
    const serviceId = Arg('serviceId');
    const assignedCoachId = Arg('staffId');
    const rootClass = cnb(css.root, {
        [css.oneapp]: isOneApp,
    });
    const buttonClass = cnb(css.confirmButton, {
        [css.oneapp]: isOneApp,
    });
    const TIMETAP_MH_LOCATION_ID = process.env.TIMETAP_MH_LOCATION_ID;

    function timezoneChange(timezone) {
        setAvailableTimes(
            availableTimes.map(date => {
                return {
                    label: localizedTime(
                        date.value,
                        timezone.value,
                        selectedLang
                    ),
                    value: date.value,
                };
            })
        );
        setSelectedTimezone(timezone);
    }

    const createNewClientObject = async () => {
        const timeZoneValues = getClientTimeZoneObject(
            selectedCountry,
            selectedTimezone.value
        );
        const clientData = {
            allowWaitListText: false,
            cellPhone: phones[0]?.number,
            emailAddress: email,
            firstName,
            fullName: `${firstName} ${lastName}`,
            lastName,
            locale: selectedLang,
            status: 'ACTIVE',
            timeZone: {
                timeZoneCode: timeZoneValues.timeZoneCode,
                timeZoneDesc: timeZoneValues.timeZoneDesc,
                timeZoneId: timeZoneValues.timeZoneId,
                visible: true,
            },
        };

        try {
            const data = await TimeTap.createNewClientObject(token, clientData);

            setClientObject(data);
        } catch (error) {
            setAPIError(error);
        }

        return;
    };

    const getClientObject = useCallback(() => {
        TimeTap.getClientObject(token, email)
            .then(data => {
                // Member is already in TimeTap database
                if (data?.length > 1) {
                    setClientObject(data[0]);
                }
                // Member needs to be added in the TimeTap database
                else if (data?.length === 1 && data[0]?.clientId === -1) {
                    createNewClientObject();
                }
            })
            .catch(error => {
                setAPIError(error);
            });

        return;
    }, [token, email]); // eslint-disable-line react-hooks/exhaustive-deps

    const getSchedulerFieldsObjects = useCallback(() => {
        TimeTap.getSchedulerFieldsObjects(token)
            .then(data => {
                setCommentFieldObject(data[0]);
                setAsstistiveDeviceFieldObject(data[1]);
                setVideoCameraFieldObject(data[2]);
                setUuidFieldObject(data[5]);
            })
            .catch(error => {
                setAPIError(error);
            });

        return;
    }, [token]);

    const getLocationObject = useCallback(() => {
        TimeTap.getLocationObject(token, TIMETAP_MH_LOCATION_ID)
            .then(data => {
                setLocationObject(data);
            })
            .catch(error => {
                setAPIError(error);
            });

        return;
    }, [token, TIMETAP_MH_LOCATION_ID]);

    const getReasonObject = useCallback(() => {
        TimeTap.getReasonObject(token, serviceId)
            .then(data => {
                setReasonObject(data);
            })
            .catch(error => {
                setAPIError(error);
            });

        return;
    }, [token, serviceId]);

    const getCoaches = useCallback(() => {
        TimeTap.getStaff(token, serviceId, TIMETAP_MH_LOCATION_ID).then(
            data => {
                const assignedCoachData = data.filter(
                    coach => coach.professionalId === Number(assignedCoachId)
                );

                setAssignedCoachName(assignedCoachData[0].internalDisplayName);
                setCoachesData(data);
            }
        );

        return;
    }, [token, serviceId, TIMETAP_MH_LOCATION_ID, assignedCoachId]);

    const getMemberMHSessions = useCallback(() => {
        TimeTap.getMemberMHSessions(token).then(data => {
            const upcomingSessions = data?.sessions?.filter(
                session => session.status === 'SCHEDULED'
            );

            if (!isEmpty(upcomingSessions)) {
                setHasUpcomingSession(true);
            }
        });

        return;
    }, [token]);

    const getCoachAvailableDates = useCallback(() => {
        const dateInstance = new Date();
        const requests = [];
        let monthsAhead = 3;
        let expiration;

        if (Arg('expirationDate')) {
            expiration = parseISO(Arg('expirationDate'));
            monthsAhead = differenceInMonths(expiration, new Date());
        }

        for (let i = 0; i <= monthsAhead; i++) {
            const year = format(add(dateInstance, {months: i}), 'yyyy');
            const month = format(add(dateInstance, {months: i}), 'MM');

            requests.push(
                TimeTap.getCoachAvailableDates(
                    token,
                    year,
                    month,
                    assignedCoachId,
                    serviceId,
                    SESSION_DURATION,
                    TIMETAP_MH_LOCATION_ID
                )
            );
        }

        Promise.all(requests)
            .then(data => {
                const availableDatesResponse = data?.flat();
                const formattedDates = availableDatesResponse.map(
                    ({month, day, year}) => {
                        const date = month
                            .toString()
                            .concat(
                                '/',
                                day.toString().concat('/', year.toString())
                            );
                        const parsedDate = parse(
                            date,
                            'M/d/yyyy',
                            dateInstance
                        );

                        return format(parsedDate, 'MM/dd/yyyy');
                    }
                );

                // Getting time slots for current day to see if there are any available that
                // are 4 hours in advance in order to mark this day in the calendar as available
                if (format(new Date(), 'MM/dd/yyyy') === formattedDates[0]) {
                    TimeTap.getCoachAvailableTimes(
                        token,
                        dateInstance.getFullYear(),
                        format(dateInstance, 'MM'),
                        format(dateInstance, 'dd'),
                        assignedCoachId,
                        serviceId,
                        SESSION_DURATION,
                        TIMETAP_MH_LOCATION_ID
                    ).then(timesData => {
                        if (!isEmpty(timesData)) {
                            const startTime = addHours(new Date(), 4).getTime();
                            const filteredTimes = timesData.filter(
                                // eslint-disable-next-line max-nested-callbacks
                                timeSlot =>
                                    timeSlot.staffStartDateTimeUTC >= startTime
                            );

                            if (isEmpty(filteredTimes)) {
                                // Remove current day because there's no available times that are 4 hours in advance
                                formattedDates.shift();
                            } else {
                                setFilteredTimeSlots(filteredTimes);
                            }
                        }
                    });
                }

                setAvailableDates(formattedDates);

                if (formattedDates.length) {
                    setInitialMonth(formattedDates[0]);
                }
            })
            .catch(error => {
                setAPIError(error);
            });

        return;
    }, [serviceId, token, assignedCoachId, TIMETAP_MH_LOCATION_ID]);

    const getAllCoachesAvailableDates = useCallback(() => {
        const dateInstance = new Date();
        const requests = [];
        let monthsAhead = 3;
        let expiration;

        if (Arg('expirationDate')) {
            expiration = parseISO(Arg('expirationDate'));
            monthsAhead = differenceInMonths(expiration, new Date());
        }

        for (let i = 0; i <= monthsAhead; i++) {
            let startDate;
            const endDate = format(
                endOfMonth(add(new Date(), {months: i})),
                DATE_FORMAT
            );

            if (i === 0) {
                startDate = format(new Date(), DATE_FORMAT);
            } else {
                startDate = format(
                    startOfMonth(add(new Date(), {months: i})),
                    DATE_FORMAT
                );
            }

            requests.push(
                TimeTap.getAllCoachesAvailableDates(
                    token,
                    serviceId,
                    startDate,
                    endDate,
                    TIMETAP_MH_LOCATION_ID
                )
            );
        }

        Promise.all(requests)
            .then(data => {
                const availableDatesResponse = data
                    ?.flat()
                    ?.filter(availableDate => availableDate.timeSlots.length);
                const parsedDates = availableDatesResponse.map(({date}) => {
                    return parse(date, DATE_FORMAT, new Date());
                });
                const formattedDates = parsedDates.map(date => {
                    return format(date, 'MM/dd/yyyy');
                });

                // Getting time slots for current day to see if there are any available that
                // are 4 hours in advance in order to mark this day in the calendar as available
                if (format(dateInstance, 'MM/dd/yyyy') === formattedDates[0]) {
                    const startTime = addHours(new Date(), 4).getTime();
                    const filteredTimes =
                        availableDatesResponse[0]?.timeSlots.filter(
                            timeSlot =>
                                timeSlot.staffStartDateTimeUTC >= startTime
                        );

                    if (isEmpty(filteredTimes)) {
                        // Remove current day because there's no available times that are 4 hours in advance
                        formattedDates.shift();
                    } else {
                        setFilteredTimeSlots(filteredTimes);
                    }
                }

                setAvailableDatesData(availableDatesResponse);
                setAvailableDates(formattedDates);

                if (parsedDates.length) {
                    setInitialMonth(format(parsedDates[0], 'MM/dd/yyyy'));
                }
            })
            .catch(error => {
                setAPIError(error);
            });

        return;
    }, [serviceId, token, TIMETAP_MH_LOCATION_ID]);

    // Trigerring a re-render for the time selection so that it shows the placeholder text for the new day selected
    const renderTimeSelection = () => {
        setTimeKey(uuidv4());
        setAvailableTimes([]);
    };

    const renderDayAndTimeSelection = () => {
        // Trigerring a re-render for the calendar so that it shows the
        // placeholder text instead of the selected day for the previous coach
        setInitialMonth(null);
        setAvailableDates([]);

        renderTimeSelection();
    };

    const onDaySelected = date => {
        const dateInstance = new Date(date);

        if (availableTimes) {
            renderTimeSelection();
        }

        if (selectedAnyCoach) {
            let timeSlots;

            // For the current day, we already filtered the time slots in getAllCoachesAvailableDates()
            if (dateInstance.toDateString() === new Date().toDateString()) {
                timeSlots = filteredTimeSlots;
            } else {
                const formattedDate = format(dateInstance, DATE_FORMAT);
                const dateData = availableDatesData.filter(
                    availableDate => availableDate.date === formattedDate
                );

                timeSlots = dateData[0].timeSlots;
            }

            setTimeSlotsData(timeSlots);
            setAvailableTimes(
                timeSlots.map(timeSlot => {
                    const convertedDate = new Date(
                        timeSlot.staffStartDateTimeUTC
                    ).toISOString();

                    return {
                        label: localizedTime(
                            convertedDate,
                            selectedTimezone.value,
                            selectedLang
                        ),
                        value: timeSlot.staffStartDateTimeUTC.toString(),
                    };
                })
            );
        } else {
            const splitDate = date.split('/');
            const month = splitDate[0];
            const day = splitDate[1];
            const year = splitDate[2];

            TimeTap.getCoachAvailableTimes(
                token,
                year,
                month,
                day,
                assignedCoachId,
                serviceId,
                SESSION_DURATION,
                TIMETAP_MH_LOCATION_ID
            )
                .then(data => {
                    let timeSlots;

                    // For the current day, we already filtered the time slots in getCoachAvailableDates()
                    if (
                        dateInstance.toDateString() ===
                        new Date().toDateString()
                    ) {
                        timeSlots = filteredTimeSlots;
                    } else {
                        timeSlots = data;
                    }

                    setTimeSlotsData(timeSlots);
                    setAvailableTimes(
                        timeSlots?.map(timeSlot => {
                            const convertedDate = new Date(
                                timeSlot.staffStartDateTimeUTC
                            ).toISOString();

                            return {
                                label: localizedTime(
                                    convertedDate,
                                    selectedTimezone.value,
                                    selectedLang
                                ),
                                value: timeSlot.staffStartDateTimeUTC.toString(),
                            };
                        })
                    );
                })
                .catch(error => {
                    setAPIError(error);
                });
        }
    };

    const onTimeSelected = time => {
        const timeSlotData = timeSlotsData.filter(
            timeSlot => timeSlot.staffStartDateTimeUTC === Number(time.value)
        );

        setSelectedTimeSlotData(timeSlotData[0]);

        if (selectedAnyCoach) {
            const timeSlotInfo = timeSlotData[0].units;

            // More than 1 coach has the time slot available
            if (timeSlotInfo.length > 1) {
                const requests = [];

                timeSlotInfo.forEach(timeSlot => {
                    requests.push(
                        TimeTap.getCoachAppointmentCount(
                            token,
                            timeSlot.professionalId
                        )
                    );
                });

                Promise.all(requests).then(data => {
                    // Finding which coach has the least amount of appointments
                    const coachInfo = data.reduce((prev, curr) => {
                        if (prev.count === curr.count) {
                            return 'sameCount';
                        }

                        return prev.count < curr.count ? prev : curr;
                    });

                    if (coachInfo === 'sameCount') {
                        // Picking a coach randomly
                        setCoachId(
                            timeSlotInfo[
                                Math.floor(Math.random() * timeSlotInfo.length)
                            ].professionalId
                        );
                    } else {
                        setCoachId(coachInfo.coachId);
                    }
                });
            } else {
                setCoachId(timeSlotInfo[0].professionalId);
            }
        }
    };

    const onSelectedAssignedCoach = () => {
        setSelectedAnyCoach(false);
        setSelectedAssignedCoach(true);

        renderDayAndTimeSelection();
    };

    const onSelectedAnyCoach = () => {
        setSelectedAnyCoach(true);
        setSelectedAssignedCoach(false);

        renderDayAndTimeSelection();
        getAllCoachesAvailableDates();
    };

    const scheduleAppointment = () => {
        const coachData = coachesData.filter(
            coach =>
                coach.professionalId ===
                (selectedAnyCoach ? coachId : Number(assignedCoachId))
        );
        const isNewCoach =
            selectedAnyCoach && coachId !== Number(assignedCoachId);

        commentFieldObject.value = notes;
        assistiveDeviceFieldObject.value = assistiveDevice;
        videoCameraFieldObject.value = videoCamera;
        uuidFieldObject.value = internalUserId;

        const appointmentData = {
            client: clientObject,
            clientEndDate: selectedTimeSlotData.clientEndDate,
            clientEndTime: selectedTimeSlotData.clientEndTime,
            clientReminderHours: 24,
            clientStartDate: selectedTimeSlotData.clientStartDate,
            clientStartTime: selectedTimeSlotData.clientStartTime,
            endDate: selectedTimeSlotData.staffEndDate,
            endTime: selectedTimeSlotData.staffEndTime,
            fields: [
                commentFieldObject,
                assistiveDeviceFieldObject,
                videoCameraFieldObject,
                uuidFieldObject,
            ],
            location: locationObject,
            note: notes,
            reason: reasonObject,
            remindStaffSmsHrs: 0,
            remindClientSmsHrs: 24,
            sendConfirmationToClient: true,
            sendConfirmationToStaff: true,
            staff: coachData[0],
            staffReminderHours: 24,
            startDate: selectedTimeSlotData.staffStartDate,
            startTime: selectedTimeSlotData.staffStartTime,
            status: 'OPEN',
        };

        TimeTap.createAppointment(token, appointmentData)
            .then(data => {
                dispatch({
                    type: 'APPOINTMENT_DETAILS',
                    payload: {
                        startDate: new Date(data?.startDateTimeUTC),
                        endDate: new Date(data?.endDateTimeUTC),
                        timezone: selectedTimezone.value,
                        type: data?.reason?.reasonDesc,
                        coach: data?.staff?.internalDisplayName,
                        isNewCoach,
                    },
                });

                MixpanelUtils.track({
                    event: 'coaching.scheduling.success',
                    properties: {
                        // eslint-disable-next-line camelcase
                        product_line: MH_MIXPANEL_PRODUCT,
                    },
                });

                navigate('/confirmationMentalHealth');
            })
            .catch(err => {
                setAPIError(err);

                MixpanelUtils.track({
                    event: 'coaching.scheduling.failure',
                    properties: {
                        // eslint-disable-next-line camelcase
                        product_line: MH_MIXPANEL_PRODUCT,
                        'Error Code': err.status,
                    },
                });
            });
    };

    const onScheduleSessionClick = () => {
        if (selectedAnyCoach && coachId !== Number(assignedCoachId)) {
            setShowSwitchingToNewCoachModal(true);
        } else {
            scheduleAppointment();
        }
    };

    const onModalClose = () => {
        setShowSwitchingToNewCoachModal(false);
    };

    useEffect(() => {
        if (token && uuid) {
            UserInfoAPI.schedulingData(token, uuid)
                .then(data => {
                    dispatch({
                        type: 'USER_DATA',
                        payload: data,
                    });
                })
                .catch(({data}) => {
                    dispatch({
                        type: 'ERROR',
                        payload: JSON.stringify(data),
                    });
                    dispatch(UserInfoAPI.incorrectToken);
                });
            UserInfoAPI.getInternalUserId(token, uuid)
                .then(data => setInternalUserId(data?.uuid))
                .catch(err => {
                    setAPIError(err);
                });
        }
    }, [token, uuid, dispatch]);

    useEffect(() => {
        if (token && serviceId && email) {
            getClientObject();
            getSchedulerFieldsObjects();
            getLocationObject();
            getReasonObject();
            getCoaches();
            getMemberMHSessions();
        }
    }, [
        token,
        serviceId,
        email,
        getClientObject,
        getSchedulerFieldsObjects,
        getLocationObject,
        getReasonObject,
        getCoaches,
        getMemberMHSessions,
    ]);

    useEffect(() => {
        if (token && serviceId) {
            if (!selectedAnyCoach) {
                getCoachAvailableDates();
            }
        }
    }, [
        token,
        serviceId,
        selectedAssignedCoach,
        selectedAnyCoach,
        getCoachAvailableDates,
    ]);

    useEffect(() => {
        if (APIError) {
            MixpanelUtils.track({
                event: 'coaching.scheduling.error',
                properties: {
                    // eslint-disable-next-line camelcase
                    product_line: MH_MIXPANEL_PRODUCT,
                    'Error Code': APIError.status,
                },
            });
        }
    }, [APIError]);

    useEffect(() => {
        MixpanelUtils.track({
            event: 'coaching.scheduling.viewed',
            properties: {
                // eslint-disable-next-line camelcase
                product_line: MH_MIXPANEL_PRODUCT,
            },
        });
    }, []);

    return (
        <div className={rootClass}>
            <AcuityError isOpen={APIError} toggleModal={setAPIError} />
            <h2 className={css.subheading}>{t('title')}</h2>
            <Form>
                <div>
                    <h3 className={css.sectionTitle}>
                        {t('myStrength.details')}
                    </h3>
                    <Select
                        id="haveAssistiveDevice"
                        label={<Label>{t('myStrength.device')}</Label>}
                        items={[
                            {
                                label: t('myStrength.yes'),
                                value: t('myStrength.yes'),
                            },
                            {
                                label: t('myStrength.no'),
                                value: t('myStrength.no'),
                            },
                        ]}
                        onChange={({value}) => setAsstistiveDevice(value)}
                    />
                    <Select
                        id="haveCamera"
                        label={<Label>{t('myStrength.video')}</Label>}
                        items={[
                            {
                                label: t('myStrength.yes'),
                                value: t('myStrength.yes'),
                            },
                            {
                                label: t('myStrength.no'),
                                value: t('myStrength.no'),
                            },
                        ]}
                        onChange={({value}) => setVideoCamera(value)}
                    />
                    <HorizontalRule spaceTop={24} spaceBottom={24} />
                </div>
                <div>
                    <h3 className={css.sectionTitle}>
                        {t('sessionInfo.mentalHealthHeading')}
                    </h3>
                    <span className={css.selectCoachHeading}>
                        {t('sessionInfo.availability')}
                    </span>
                    {assignedCoachName ? (
                        <div className={css.chipsContainer}>
                            <Chip
                                id="coach"
                                label={assignedCoachName}
                                checked={selectedAssignedCoach}
                                onChange={() => onSelectedAssignedCoach()}
                            />
                            {!hasUpcomingSession && (
                                <Chip
                                    classNameRoot={css.chip}
                                    id="anyCoach"
                                    label={t('sessionInfo.anyCoach')}
                                    checked={selectedAnyCoach}
                                    onChange={() => onSelectedAnyCoach()}
                                />
                            )}
                        </div>
                    ) : (
                        <Loader classNameRoot={css.loader} />
                    )}
                    {selectedAnyCoach && (
                        <div className={css.infoBanner}>
                            <div className={css.infoBannerHeadingContainer}>
                                <IconInfoDefault className={css.iconInfo} />
                                <p>{t('sessionInfo.infoBanner.heading')}</p>
                            </div>
                            <p className={css.infoBannerDescription}>
                                {t('sessionInfo.infoBanner.description')}
                            </p>
                        </div>
                    )}
                    <Select
                        id="timeZone"
                        label={<Label>{t('sessionInfo.timezone')}</Label>}
                        placeholder={t('sessionInfo.timezonePlaceholder')}
                        defaultValue={t(selectedTimezone?.value)}
                        items={timeZones[selectedCountry].map(
                            ({value, label}) => ({
                                value,
                                label: t(`timeZone.${label}`),
                            })
                        )}
                        onChange={tz => timezoneChange(tz)}
                        required
                    />
                    <DatePicker
                        key={initialMonth}
                        id="date"
                        name="date"
                        classNameItem={css.dateInput}
                        calendar={{
                            disablePast: true,
                            disableBefore: initialMonth,
                            initialMonth,
                            available: availableDates,
                        }}
                        disabled={!initialMonth}
                        label={<Label> {t('sessionInfo.date')}</Label>}
                        onChange={date => onDaySelected(date)}
                        required
                    />
                    <Select
                        key={timeKey}
                        id="time"
                        name="time"
                        disabled={isEmpty(availableTimes)}
                        label={<Label> {t('sessionInfo.time')}</Label>}
                        items={availableTimes}
                        onChange={time => onTimeSelected(time)}
                        placeholder={t('sessionInfo.timePlaceholder')}
                        required
                    />
                    <HorizontalRule spaceTop={24} spaceBottom={24} />
                </div>
                <TextArea
                    id="note"
                    name="note"
                    maxLength={255}
                    showCounter
                    label={
                        <Label className={css.sectionTitle}>
                            {t('sessionInfo.notes')}
                        </Label>
                    }
                    placeholder={t('sessionInfo.notesPlaceholder')}
                    onChange={e => setNotes(e.target.value)}
                />
                {!isOneApp && (
                    <Button
                        variant="secondary"
                        className={css.backButton}
                        onClick={goToMyStrength}
                    >
                        <IconArrowLeftDefault />
                    </Button>
                )}
                <Button
                    type="submit"
                    variant="primary"
                    className={buttonClass}
                    onClick={onScheduleSessionClick}
                >
                    {t('sessionInfo.submit')}
                </Button>
                <Modal
                    classNameContainer={css.modalTitle}
                    isOpen={showSwitchingToNewCoachModal}
                    title={<h2>{t('sessionInfo.infoBanner.heading')}</h2>}
                    onRequestClose={onModalClose}
                    primaryAction={{
                        variant: 'primary',
                        onClick: scheduleAppointment,
                        children: t('sessionInfo.confirm'),
                    }}
                    secondaryAction={{
                        variant: 'secondary',
                        onClick: onModalClose,
                        children: t('sessionInfo.cancel'),
                    }}
                >
                    <p>{t('sessionInfo.infoBanner.description')}</p>
                </Modal>
            </Form>
        </div>
    );
};

export default SchedulingFormMentalHealth;
