import {format, utcToZonedTime} from 'date-fns-tz';
import Arg from '@livongo/arg';

const CommonUtils = {
    /**
     * Checks to see if a feature in the feature flag list is enabled
     * @static
     * @function isFeatureEnabled
     * @param {Array} - The list of feature objects from context
     * @param {String} - The target name of feature in string format
     * @param {Boolean} - Check current application is oneApp
     * @example
     * CommonUtils.isFeatureEnabled(...,...,..);
     * @returns boolean
     */
    isFeatureEnabled(featureList, featureName, isOneApp) {
        return featureList?.some(
            feature =>
                feature.name === featureName &&
                ((!isOneApp && feature.legacyActive) ||
                    (isOneApp && Arg('enableNewUi')))
        );
    },
    redirectToOneAppCoaching(isProd, returnSubDomain) {
        if (isProd) {
            if (returnSubDomain) {
                return `https://${returnSubDomain}.teladoc.com/condition-management/coaching`;
            }

            return 'https://my.teladoc.com/condition-management/coaching';
        } else {
            if (returnSubDomain) {
                return `https://${returnSubDomain}/condition-management/coaching`;
            }

            return `${process.env.ONEAPP_URL}/condition-management/coaching`;
        }
    },
    redirectToOneApp(isProd, returnSubDomain, returnPath) {
        if (isProd) {
            if (returnSubDomain && returnPath) {
                return `https://${returnSubDomain}.teladoc.com${returnPath}`;
            }

            return 'https://my.teladoc.com/account/profile';
        } else {
            if (returnSubDomain && returnPath) {
                return `https://${returnSubDomain}${returnPath}`;
            }

            return `${process.env.ONEAPP_URL}/account/profile`;
        }
    },
    openGoogleCalendar({start, end, name}) {
        window.open(
            `https://calendar.google.com/calendar/u/0/r/eventedit?text=${name}&dates=${format(
                start,
                'yyyyLLdd'
            )}T${format(start, 'HHmmss')}/${format(end, 'yyyyLLdd')}T${format(
                end,
                'HHmmss'
            )}`
        );
    },
    openOutLookCalender({end, start, name, mwUrl, alreadyFormtted}) {
        if (alreadyFormtted) {
            window.open(
                `${mwUrl}/v1/coach/ical/event/?end=${end}&start=${start}&summary=${encodeURI(
                    name
                )}`
            );
        } else {
            window.open(
                `${mwUrl}/v1/coach/ical/event/?end=${format(
                    utcToZonedTime(end, 'UTC'),
                    'yyyyMMddHHmmss'
                )}&start=${format(
                    utcToZonedTime(start, 'UTC'),
                    'yyyyMMddHHmmss'
                )}&summary=${encodeURI(name)}`
            );
        }
    },
    getMonthDateFormat(dt) {
        const month = [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December',
        ];

        if (dt) {
            const d = new Date(format(utcToZonedTime(dt, 'UTC'), 'MM dd'));

            return `${month[d.getMonth()]} ${d.getDate()}`;
        }
    },

    getTimeFormat(dt) {
        if (dt) {
            const [ampm, zone, time] = dt.split(' ').reverse();

            return `${time} ${zone} ${ampm}`;
        }
    },
    getFormattedType(callType) {
        return callType === 'initial' || callType === 'follow-up'
            ? `${callType} call`
            : callType;
    },
};

export default CommonUtils;
